@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Mulish', sans-serif !important;
}

html,
body,
#root {
  height: 100%;
}

.green-text-clamp {
  font-size: clamp(4rem, 9vw, 6.25rem);
}

.text-clamp-16-33 {
  font-size: clamp(1.5rem, 3vw, 2.0625rem);
  line-height: 1.2
}

.white-text-clamp {
  font-size: clamp(1.5rem, 3vw, 2.5rem);
  line-height: 1.2
}

.white-text-clamp-tighter {
  font-size: clamp(1.5rem, 3vw, 2.5rem);
  line-height: 0.4
}


@keyframes draw {
  from {
    stroke-dashoffset: 1;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.lightCirclePath {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: draw 1s linear forwards;
  animation-delay: 0.5s;
}

@keyframes door-8-line-group {
  0% {
    transform: scaleY(0);
  }
  10% {
    transform: scaleY(1);
  }
  75% {
    opacity: 1;
  }
  85% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.door-8-line-group {
  transform-origin: center;
  animation: door-8-line-group ease-in-out 5s infinite;
}

.door-8-line-1 {
  transform: rotate(180deg);
  transform-origin: center;
}

@keyframes door-8-line-2 {
  20% {
    transform: rotate(0);
  }
  45% {
    transform: rotate(45deg);
  }
  70% {
    transform: rotate(0);
  }
}

.door-8-line-2 {
  transform-origin: center;
  animation: door-8-line-2 ease-in-out 5s infinite;
}

@keyframes door-8-line-3 {
  20% {
    transform: rotate(0);
  }
  45% {
    transform: rotate(90deg);
  }
  70% {
    transform: rotate(0);
  }
}

.door-8-line-3 {
  transform-origin: center;
  animation: door-8-line-3 ease-in-out 5s infinite;
}

@keyframes door-8-line-4 {
  20% {
    transform: rotate(0);
  }
  45% {
    transform: rotate(135deg);
  }
  70% {
    transform: rotate(0);
  }
}

.door-8-line-4 {
  transform-origin: center;
  animation: door-8-line-4 ease-in-out 5s infinite;
}
